<template>
  <div>
    <div class="home" v-show="ishome">
      <div v-if="msg" id="msg" v-cloak>
        <template v-if="$route.params.id">
          {{ msg }}
        </template>
        <div v-else class="container" style="margin: 0 20px">
          <h1 style="font-size: 24px; margin-bottom: 16px">
            {{ $t("index.companyName") }}
          </h1>
          <ul style="font-size: 16px">
            <li>
              {{ $t("index.briefIntroduction") }}
            </li>

            <li style="margin-top: 10px">
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">{{
                $t("index.beian")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else-if="appInfo.status === 'hidden' || appInfo.switch == 1"
        style="
          width: 80%;
          text-align: center;
          background-color: blue;
          word-wrap: break-word;
          word-break: break-all;
          min-height: 200rpx;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        {{
          appInfo.download_error ? appInfo.download_error : $t("index.yixiajia")
        }}
      </div>
      <div
        v-else-if="appInfo.uploadpwd && pwdTxt !== appInfo.uploadpwd"
        id="password"
        v-cloak
      >
        <div style="text-align: left">
          {{ $t("index.mima") }}：<input
            type="text"
            v-model="pwd"
            :placeholder="$t('index.mimaPlaceholder')"
          />
        </div>
        <div
          style="
            margin-top: 10px;
            text-align: left;
            padding-left: 41px;
            color: red;
          "
        >
          {{ pwdMsg ? $t("index.mimac") : "" }}
        </div>
        <button
          type="button"
          @click="tijiao"
          style="
            border: 1px solid #ccc;
            padding: 5px 10px;
            border-radius: 5px;
            margin-top: 10px;
            text-align: right;
          "
        >
          {{ $t("index.tijiao") }}
        </button>
      </div>

      <div class="ember-view">
        <main
          class="selfclear is-apps-theme"
          v-cloak
          v-if="
            !msg &&
            appInfo.status !== 'hidden' &&
            appInfo.switch == 0 &&
            pwdTxt === appInfo.uploadpwd
          "
        >
          <input
            id="localnav-menustate"
            class="localnav-menustate"
            type="checkbox"
          />

          <div
            id="localnav-sticky-placeholder"
            class="css-sticky localnav-sticking"
          ></div>
          <nav
            id="localnav"
            class="css-sticky we-localnav localnav we-localnav--app-store localnav-sticking"
            data-sticky=""
          >
            <div class="localnav-wrapper">
              <div class="localnav-background we-localnav__background"></div>
              <div class="localnav-content we-localnav__content">
                <div class="localnav-title we-localnav__title">
                  <a href="javascript:;" data-we-link-to-exclude=""
                    ><span
                      class="we-localnav__title__product"
                      data-test-we-localnav-store-title=""
                    >
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.appstore")
                        }}</font>
                      </font>
                    </span></a
                  >
                </div>
                <div
                  class="localnav-menu we-localnav__menu we-localnav__menu--apps"
                >
                  <div class="localnav-actions we-localnav__actions"></div>
                </div>
              </div>
            </div>
          </nav>
          <span class="we-localnav__shim" aria-hidden="true"></span>
          <label id="localnav-curtain" for="localnav-menustate"></label>

          <div class="animation-wrapper is-visible">
            <section class="l-content-width section section--hero product-hero">
              <div class="l-row">
                <div
                  class="product-hero__media l-column small-5 medium-4 large-3 small-valign-top"
                >
                  <picture
                    data-test-we-artwork=""
                    class="we-artwork we-artwork--downloaded product-hero__artwork we-artwork--fullwidth we-artwork--ios-app-icon"
                    style="--background-color: #ffe577"
                    id="ember3"
                  >
                    <img
                      class="ember3"
                      :src="appInfo && appInfo.image"
                      decoding="async"
                      alt=""
                      style="border-radius: 22.5%"
                      :class="[pcFlag ? 'w230' : 'we-artwork__image']"
                    />
                  </picture>
                </div>

                <div class="l-column small-7 medium-8 large-9 small-valign-top">
                  <header
                    class="product-header app-header product-header--padded-start"
                  >
                    <h1 class="product-header__title app-header__title">
                      <font
                        style="
                          vertical-align: inherit;
                          overflow-wrap: break-word;
                        "
                      >
                        <font style="vertical-align: inherit">{{
                          appInfo.ipa_name
                        }}</font>
                      </font>
                      <!-- <span class="badge badge--product-title">
                        <font style="vertical-align: inherit">
                          <font style="vertical-align: inherit">4+</font>
                        </font>
                      </span> -->
                    </h1>
                    <h3 class="app-header__designed-for">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit"
                          >{{ appInfo.mimetype }} {{ $t("index.yy") }}</font
                        >
                      </font>
                    </h3>

                    <ul class="product-header__list app-header__list"></ul>

                    <div class="installBox" v-if="!pcFlag">
                      <a
                        v-if="!downloadFlag"
                        class="down"
                        href="javascript:;"
                        @click="installApp(false)"
                        >{{ $t("index.install") }}</a
                      >
                      <span v-else>{{ $t("index.xzz") }}</span>
                    </div>
                  </header>

                  <div
                    v-if="pcFlag"
                    style="
                      width: 150px;
                      height: 100px;
                      margin-top: 10px;
                      float: right;
                      text-align: center;
                    "
                  >
                    <div>
                      <img :src="qrcode" alt="" style="width: 100px" />
                    </div>
                    <div style="margin-top: 0px">{{ $t("index.xiazai") }}</div>
                  </div>
                </div>
              </div>
            </section>

            <div
              class="comment l-content-width"
              v-if="!pcFlag"
              style="width: 87.5%"
            >
              <strong class="publicTitle2">{{ $t("index.comment") }}</strong>
              <div class="left">
                <b>5.0</b>
                <p>{{ $t("index.points") }}</p>
              </div>
              <div class="right">
                <div class="star_row">
                  <span class="s1"><i></i></span>
                  <div class="lineBox"><var class="v1"></var></div>
                </div>
                <div class="star_row">
                  <span class="s2"><i></i></span>
                  <div class="lineBox"><var class="v2"></var></div>
                </div>
                <div class="star_row">
                  <span class="s3"><i></i></span>
                  <div class="lineBox"><var class="v3"></var></div>
                </div>
                <div class="star_row">
                  <span class="s4"><i></i></span>
                  <div class="lineBox"><var class="v4"></var></div>
                </div>
                <div class="star_row">
                  <span class="s5"><i></i></span>
                  <div class="lineBox"><var class="v5"></var></div>
                </div>
                <p>19k {{ $t("index.Score") }}</p>
              </div>
            </div>

            <!-- PC样式 -->
            <section
              class="l-content-width section section--bordered section--information"
              v-if="pcFlag"
            >
              <div>
                <h2 class="section__headline">
                  <font style="vertical-align: inherit">
                    <font style="vertical-align: inherit">{{
                      $t("index.info")
                    }}</font>
                  </font>
                </h2>
                <dl
                  class="information-list information-list--app medium-columns l-row"
                >
                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.size")
                        }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          appInfo.filesize
                        }}</font>
                      </font>
                    </dd>
                  </div>

                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.updateTime")
                        }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          appInfo.updatetime
                        }}</font>
                      </font>
                    </dd>
                  </div>

                  <!-- <div
                  class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                >
                  <dt class="information-list__item__term medium-valign-top">
                    <font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">{{$t('index.jrx')}}</font></font
                    >
                  </dt>
                  <dd class="information-list__item__definition">
                    <dl class="information-list__item__definition__item">
                      <dd class="information-list__item__definition__item__definition">
                        <font style="vertical-align: inherit"
                          ><font style="vertical-align: inherit"
                            >{{$t('index.jrxtxt')}}</font
                          ></font
                        >
                      </dd>
                    </dl>
                  </dd>
                </div> -->

                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.yuyan")
                        }}</font>
                      </font>
                    </dt>
                    <dd
                      class="we-truncate we-truncate--multi-line we-truncate--interactive information-list__item__definition"
                    >
                      <div data-clamp="" class="we-clamp">
                        <p dir="ltr" data-test-bidi="">
                          <font style="vertical-align: inherit">
                            <font style="vertical-align: inherit">{{
                              $t("index.yytext")
                            }}</font>
                          </font>
                        </p>
                      </div>
                    </dd>
                  </div>
                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.age")
                        }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.agetext")
                        }}</font>
                      </font>
                    </dd>
                  </div>

                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">Copyright</font>
                      </font>
                    </dt>
                    <dd
                      class="information-list__item__definition information-list__item__definition--copyright"
                    >
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          appInfo.copyright
                        }}</font>
                      </font>
                    </dd>
                  </div>
                  <div
                    class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                  >
                    <dt class="information-list__item__term medium-valign-top">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.jiage")
                        }}</font>
                      </font>
                    </dt>
                    <dd class="information-list__item__definition">
                      <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">{{
                          $t("index.free")
                        }}</font>
                      </font>
                    </dd>
                  </div>

                  <!-- <div
                  class="information-list__item l-column small-12 medium-6 large-4 small-valign-top"
                >
                  <dt class="information-list__item__term medium-valign-top">
                    <font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">{{$t('index.jb')}}</font></font
                    >
                  </dt>
                  <dd class="information-list__item__definition">
                      <a href="javascript:;" class="link" @click="report">
                        <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{$t('index.djjb')}}</font></font>
                      </a>
                  </dd>
                </div> -->
                </dl>
              </div>
            </section>
            <section
              class="l-content-width section section--bordered section--information"
              v-if="
                pcAdShow &&
                pcFlag &&
                pcAd.status === 'show' &&
                whiteList.indexOf(appInfo.user_id) === -1 &&
                appInfo.point <= 0
              "
            >
              <div
                style="
                  margin: 0 auto;
                  max-width: 80%;
                  display: flex;
                  justify-content: center;
                  position: relative;
                  cursor: pointer;
                "
                @click="jumpUrl(pcAd.url)"
              >
                <img
                  :src="
                    pcAd.image.includes('https://')
                      ? pcAd.image
                      : pcAd.url + pcAd.image
                  "
                  alt=""
                  srcset=""
                  style="max-width: 100%; height: auto"
                />
                <div class="topright" @click.stop="pcAdShow = false">x</div>
              </div>
            </section>
            <section
              v-if="appInfo.preview && appInfo.preview.length > 0"
              class="l-content-width section section--bordered"
            >
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit"
                    >iPhone {{ $t("index.jt") }}</font
                  >
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <div class="we-screenshot-viewer__screenshots">
                  <ul
                    class="l-row l-row--peek we-screenshot-viewer__screenshots-list"
                  >
                    <li
                      v-for="(item, index) in appInfo.preview"
                      :key="index"
                      class="l-column small-2 medium-3 large-3"
                    >
                      <img
                        class="we-artwork__image ember23"
                        :src="item"
                        decoding="async"
                        alt=""
                        height="649"
                        width="300"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <!-- <section class="l-content-width section section--bordered">
            <div class="section__description">
              <h2 class="section__headline visuallyhidden">
                <font style="vertical-align: inherit"
                  ><font style="vertical-align: inherit">{{$t('index.ms')}}</font></font
                >
              </h2>
              <div class="l-row">
                <div
                  class="we-truncate we-truncate--multi-line we-truncate--interactive l-column small-12 medium-9 large-8"
                >
                  <div data-clamp="" class="we-clamp">
                    <p dir="ltr" data-test-bidi="">
                      <font style="vertical-align: inherit"
                        ><font style="vertical-align: inherit">{{
                          appInfo.fileinfo
                            ? appInfo.fileinfo
                            : $t('index.empty')
                        }}</font></font
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> -->

            <section
              class="l-content-width section section--bordered"
              v-if="appInfo.logmassage"
            >
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{
                    $t("index.Updatecontent")
                  }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <p>{{ appInfo.logmassage }}</p>
              </div>
            </section>
            <section
              class="l-content-width section section--bordered"
              v-if="appInfo.fileinfo"
            >
              <h2 class="section__headline">
                <font style="vertical-align: inherit">
                  <font style="vertical-align: inherit">{{
                    $t("index.Applicationintroduction")
                  }}</font>
                </font>
              </h2>
              <div class="we-screenshot-viewer">
                <p>{{ appInfo.fileinfo }}</p>
              </div>
            </section>

            <!-- 移动端样式 -->
            <div
              class="appInfo"
              v-if="!pcFlag"
              style="width: 87.5% !important; margin: 0 auto"
            >
              <strong class="publicTitle">{{ $t("index.info") }}</strong>
              <div class="box">
                <ul>
                  <li>
                    <span>{{ $t("index.size") }}</span>
                    <p>{{ appInfo.filesize }}</p>
                  </li>
                  <li>
                    <span>{{ $t("index.updateTime") }}</span>
                    <p>{{ appInfo.updatetime }}</p>
                  </li>
                  <!-- <li>
								<span>{{$t('index.jrx')}}</span>
								<p>{{$t('index.jrxtxt')}}</p>
							</li> -->
                  <li>
                    <span>{{ $t("index.yuyan") }}</span>
                    <p>{{ $t("index.yytext") }}</p>
                  </li>
                  <li>
                    <span>{{ $t("index.age") }}</span>
                    <p>{{ $t("index.agetext") }}</p>
                  </li>
                  <li>
                    <span>Copyright</span>
                    <p>{{ appInfo.copyright }}</p>
                  </li>
                  <li>
                    <span>{{ $t("index.jiage") }}</span>
                    <p>{{ $t("index.free") }}</p>
                  </li>
                  <li>
                    <span>{{ $t("index.yszc") }}</span>
                    <p v-if="appInfo.support_url">
                      <a :href="appInfo.support_url">✋</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
              style="
                margin: 0 auto;
                max-width: 90%;
                display: flex;
                justify-content: center;
                position: relative;
                cursor: pointer;
              "
              @click="jumpUrl(mobileAd.url)"
              v-if="
                mobileAdShow &&
                !pcFlag &&
                mobileAd.status === 'show' &&
                whiteList.indexOf(appInfo.user_id) === -1 &&
                appInfo.point <= 0
              "
            >
              <img
                :src="
                  mobileAd.image.includes('https://')
                    ? mobileAd.image
                    : mobileAd.url + mobileAd.image
                "
                alt=""
                srcset=""
                style="max-width: 100%; height: auto"
              />
              <div class="topright" @click.stop="mobileAdShow = false">x</div>
            </div>

            <div
              class="appInfo"
              v-if="appInfo && appInfo.isadvertisingisaon_status == 1"
            >
              <a
                :href="
                  appInfo &&
                  appInfo.isadvertisingisaon &&
                  appInfo.isadvertisingisaon.url
                "
              >
                <img
                  :src="
                    appInfo &&
                    appInfo.isadvertisingisaon &&
                    appInfo.isadvertisingisaon.image
                  "
                  style="width: 100%"
                  alt=""
                  srcset=""
                />
              </a>
            </div>
          </div>
        </main>
      </div>

      <div
        class="footer"
        v-if="
          !msg &&
          appInfo.status !== 'hidden' &&
          appInfo.switch == 0 &&
          pwdTxt === appInfo.uploadpwd
        "
      >
        <p>
          {{ $t("index.disclaimer") }}：{{ $t("index.disclaimertext") }}
          <a href="javascript:;" style="margin-left: 5px" @click="report">
            <font style="vertical-align: inherit">
              <font style="vertical-align: inherit; color: #a9a9a9">{{
                $t("index.djjb")
              }}</font>
            </font>
          </a>
        </p>
      </div>

      <mu-dialog title="" width="95%" :open.sync="reportFlag">
        <form onsubmit="return false;">
          <div class="ui-form-item ui-border-b">
            <label> {{ $t("index.name") }}： </label>
            <input v-model="username" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label> {{ $t("index.IDnumber") }}： </label>
            <input v-model="sf" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label> {{ $t("index.content") }}： </label>
            <textarea v-model="title" rows="4"></textarea>
          </div>
          <div class="ui-form-item ui-border-b">
            <label> {{ $t("index.phonenumber") }}： </label>
            <input v-model="mobile" type="text" placeholder="" />
          </div>
          <div class="ui-form-item ui-border-b">
            <label> {{ $t("index.identifyingcode") }}： </label>
            <input
              v-model="cache"
              type="text"
              placeholder=""
              style="width: 80px"
            />
            <button
              class="ui-btn"
              @click="sendSms"
              style="margin-top: 6px; margin-left: 5px"
            >
              {{ $t("index.Verification") }}
            </button>
          </div>
        </form>

        <mu-button slot="actions" color="primary" @click="closeReport">{{
          $t("index.qx")
        }}</mu-button>
        <mu-button slot="actions" color="primary" @click="saveReport">{{
          $t("index.tijiao")
        }}</mu-button>
      </mu-dialog>
    </div>
    <hongbao
      v-show="!ishome"
      @back="toggleBack"
      :appInfo="appInfo"
      :hongbaoAd="hongbaoAd"
      :whiteList="whiteList"
      :jumpObject="jumpObject"
    />
    <div class="modalClass" v-show="modalShow">
      <div style="margin-top: 25px">{{ $t("index.plaeseComputed") }}：</div>
      <div
        style="
          margin-top: 25px;
          width: 100%;
          text-align: center;
          font-size: 16px;
          color: #333;
        "
      >
        <span>{{ validateInfo.num1 }}</span>
        <span style="margin: 0 15px">+</span>
        <span>{{ validateInfo.num2 }}</span>
        <span style="margin-left: 15px">= ？</span>
      </div>
      <input
        v-model="result"
        type="number"
        :placeholder="$t('index.inputResult')"
        style="margin-top: 25px; height: 30px"
      />
      <div style="margin: 25px auto" class="confirm" @click="confirm">
        {{ $t("index.confirm") }}
      </div>
      <div class="close" @click="modalShow = false">×</div>
    </div>
  </div>
</template>

<script>
// import { Swiper } from 'swiper';
// import router from "@/router";
import hongbao from "./hongbao";
export default {
  components: {
    // Swiper,
    hongbao,
  },
  name: "Home",
  data() {
    return {
      ishome: true,
      messageleo: "Hello qiaohui!",
      appInfo: {},
      loading: true,
      downIosFlag: false,
      msg: "",
      downloadFlag: false,
      pwd: "",
      pwdMsg: false,
      // pwdTxt: null,
      pwdTxt: "",
      reportFlag: false,
      reportname: "",
      reportMobile: "",
      reportContent: "",

      mobile: "",
      username: "",
      sf: "",
      title: "",
      cache: "",
      pcFlag: false,
      qrcode: "",
      baseUrl: window.location.origin || "https://jzyx.ink", // 测试服：https://administrators.yunqianhui.com.cn  正式服：https://fzapp.jzyweb.ink   英文版后台地址 https://a.qweapp.com
      // baseUrl: "https://test.ejuzhen.com",
      uploadtoken: "",
      language: "chinese",
      mobileAd: {},
      mobileAdShow: true,
      pcAd: {},
      pcAdShow: true,
      hongbaoAd: {},
      jumpObject: {},
      whiteList: "",
      validateInfo: {
        num1: 0,
        num2: 0,
        result: -1,
      },
      modalShow: false,
      result: "",
    };
  },
  created() {
    const language = navigator.language;
    if (language == "zh-CN") {
      this.$i18n.locale = "zh";
    } else if (language == "en") {
      this.$i18n.locale = "en";
    } else if (language == "zh-TW") {
      this.$i18n.locale = "tw";
    } else if (language == "vi") {
      //越南语
      this.$i18n.locale = "vi";
    }
    this.getAonList();
  },
  mounted() {
    var isWeixin = this.is_weixin();
    var cssText =
      "#weixin-tip{position: fixed; left:0; top:0; background: #666; width: 100%; height:100%; z-index: 10000;} #weixin-tip p{text-align: center; margin-top: 10%; padding:0 5%;}";
    // var cssText =
    //   "#weixin-tip{position: fixed; left:0; top:0; background: rgb(0,0,0); width: 100%; height:100%; z-index: 10000;opacity:.7;} #weixin-tip p{text-align: center; margin-top: 10%; padding:0 5%;}";
    if (isWeixin) {
      this.loadHtml();
      this.loadStyleText(cssText);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        this.pcFlag = false;
      } else {
        this.pcFlag = true;
      }
    });

    this.init();
  },
  watch: {
    modalShow(show) {
      if (show) {
        let num1 = this.getRandom(1, 49);
        let num2 = this.getRandom(1, 49);
        this.validateInfo.num1 = num1;
        this.validateInfo.num2 = num2;
        this.validateInfo.result = Number(parseFloat(num1 + num2).toFixed(2)); 
      }
    },
  },
  methods: {
    getRandom(min = 1, max = 49) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    jumpUrl(url) {
      if (url && url.includes("http")) {
        window.open(url);
      }
    },
    toggleBack() {
      this.ishome = !this.ishome;
    },
    getAonList() {
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/aon/aon_list",
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == 1) {
            res.data.data.forEach((item) => {
              if (item.web === "mobile") {
                this.mobileAd = item;
              }
              if (item.web === "hongbao") {
                this.hongbaoAd = item;
              }
              if (item.web === "pc_dowload") {
                this.pcAd = item;
              }
            });
          } else {
          }
        }
      });
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/aon/system_info",
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.code === 1) {
            this.jumpObject = res.data.data.find((item) => {
              return item.name === "hongbao_url";
            });
            this.whiteList = res.data.data.find((item) => {
              return item.name === "aonlist";
            }).value;
          }
        }
      });
    },
    loadStyleText(cssText) {
      var style = document.createElement("style");
      style.rel = "stylesheet";
      style.type = "text/css";
      try {
        style.appendChild(document.createTextNode(cssText));
      } catch (e) {
        style.styleSheet.cssText = cssText; //ie9以下
      }
      var head = document.getElementsByTagName("head")[0]; //head标签之间加上style样式
      head.appendChild(style);
    },
    loadHtml() {
      var div = document.createElement("div");
      div.id = "weixin-tip";
      div.innerHTML = '<p><img src="./point.png" style="width: 100%;" /></p>'; //遮罩图片
      document.body.appendChild(div);
    },
    getQrCode(id) {
      const params = {
        id: id,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/AppPrcode",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          if (data.code === 1) {
            // this.downloadFlag = true;
            this.pcFlag = true;

            this.qrcode = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveReport() {
      this.loading = true;
      const params = {
        username: this.username,
        sf: this.sf,
        title: this.title,
        cache: this.cache,
        mobile: this.mobile,
        appid: this.appInfo.id,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/Tfsign/report",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 1) {
            this.reportFlag = false;
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.jbcg"),
            });
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.jbsb"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendSms() {
      // 发送验证码
      if (!this.mobile) {
        this.$toast.message({
          close: false,
          position: "top",
          message: this.$t("index.qssjh"),
        });
        return;
      }
      const params = {
        mobile: this.mobile,
        event: "report",
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/Sms/send",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          if (data.code === 1) {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.hqcg"),
            });
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: this.$t("index.hqsb"),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeReport() {
      this.reportFlag = false;
    },
    report() {
      this.reportFlag = true;
    },
    tijiao() {
      this.pwdMsg = this.pwd !== this.appInfo.uploadpwd;
      this.pwdTxt = this.pwd;
    },
    downIosFun() {
      this.$axios({
        method: "GET",
        url: this.baseUrl + "/api/qnupload/ces",
        // headers: {
        // 	"Access-Control-Allow-Origin": "*",
        // 	'Content-Type': 'application/x-www-form-urlencoded',
        // },
        data: {
          id: window.location.pathname.slice(1),
          // id: 'c6PaXV'
        },
      })
        .then(({ data }) => {
          window.location.href =
            "itms-services://?action=download-manifest&url=" + data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = decodeURI(window.location.search.substr(1)).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    confirm() {
      if (this.result && Number(this.result) === this.validateInfo.result) {
        this.installApp(true);
      } else {
        this.result = ''
      }
    },
    installApp(validate = false) {
      if (!validate) {
        if (this.appInfo.need_validate == 2) {
          this.modalShow = true;
          return;
        } else {
          this.modalShow = false;
        }
      } else {
        this.modalShow = false;
      }
      this.result = "";
      if (this.whiteList.indexOf(this.appInfo.user_id) === -1) {
        this.ishome = false;
      }

      this.downIosFlag = false;
      this.loading = true;
      const params = {
        id: this.appInfo.id,
        qiniutoken: this.uploadtoken,
        code: this.getQueryString("code"),
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/DecUpload",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 1) {
            this.downloadFlag = true;
            if (this.appInfo.mimetype === "Android") {
              window.open(this.appInfo.url);
              return;
              let a = document.createElement("a");
              a.href = this.appInfo.url;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else if (this.appInfo.mimetype === "iOS") {
              this.downIosFlag = true;
              window.location =
                "itms-services://?action=download-manifest&url=" +
                window.encodeURIComponent(
                  this.baseUrl +
                    "/api/qnupload/PlistDownLoad?id=" +
                    this.appInfo.onlyf
                );
              // document.querySelector('#downIos').click();
            } else {
              window.open(this.appInfo.url);
              // window.location = this.appInfo.url;
            }
          } else {
            this.$toast.message({
              close: false,
              position: "top",
              message: data.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    queryQiniuToken(fields) {
      let bucket = "";
      if (fields.mimetype == "Android") {
        // 安卓
        bucket = "jz-apkk";
      } else if (fields.mimetype == "iOS") {
        // IOS
        bucket = "jz-ios";
      } else {
        // IOS免签 Web Clip
        bucket = "jz-mobileconfig";
      }
      const params = {
        bucket: bucket,
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/GetQiNiuToken",
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(({ data }) => {
          if (data.code === 1) {
            this.uploadtoken = data.data.uploadtoken;
          } else {
            this.msg = data.msg;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    is_weixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    ismobile(test) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      if (
        /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
        /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
          navigator.userAgent
        )
      ) {
        if (window.location.href.indexOf("?mobile") < 0) {
          try {
            if (/iPhone|mac|iPod|iPad/i.test(navigator.userAgent)) {
              return "0";
            } else {
              return "1";
            }
          } catch (e) {}
        }
      } else if (u.indexOf("iPad") > -1) {
        return "0";
      } else {
        return "1";
      }
    },
    init() {
      this.pcFlag = false;

      const params = {
        id: this.$route.params.id,
        // id: "uPCQ0z",
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      this.$axios({
        method: "post",
        url: this.baseUrl + "/api/qnupload/GetPlistInfo",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then(({ data }) => {
          // data.code = 1;
          // data.data = {
          //   id: 15396,
          //   user_id: 8813,
          //   filename: "9d92a815-9330-46a2-8b46-25e36d06dcce.apk",
          //   filesize: "21.7M",
          //   mimetype: "Android",
          //   extparam: "",
          //   createtime: "2023-10-05 17:24:08",
          //   updatetime: "2023-12-11 21:53:02",
          //   uploadtime: 1696497848,
          //   storage: "qiniu",
          //   sha1: "nmrFsSYbUKg4UxGUbw-R5YJ85TyJ",
          //   plist: null,
          //   url: "https://apk.neicehu.top/9d92a815-9330-46a2-8b46-25e36d06dcce.apk?e=1702307105&token=Z2_Ygatc6VrZwpw-7I6arf4lgVPQ2BXZCfDKcGMh:XJTuUWRIJ-NGKoz95LnXHlRLHNU=",
          //   image:
          //     "https://imgg.jzy.ink/Fq81MRKRC_xeNosL4R50MPTPvm0v?e=1702307105&token=Z2_Ygatc6VrZwpw-7I6arf4lgVPQ2BXZCfDKcGMh:4LYh2qgETjMsn1niBAkvJH8VMlM=",
          //   identifier: "livehelper.manyun",
          //   version: "118",
          //   ipa_name: "无人直播",
          //   fileinfo: null,
          //   status: "show",
          //   uploadnum: 0,
          //   support_url: null,
          //   copyright: null,
          //   logmassage: "",
          //   uploadpwd: "",
          //   need_validate: 2,
          //   preview: "",
          //   teamname: "",
          //   xlversion: "1.1.8",
          //   erweima: null,
          //   onlyf: "aCKg9e",
          //   switch: "0",
          //   downsize: 999999,
          //   overduetime: null,
          //   appurl: null,
          //   isapply: "2",
          //   isdown: "1",
          //   isadvertisingisaon: null,
          //   type: "app",
          //   template: 0,
          //   language: "chinese",
          //   download_error: null,
          //   thumb_style: "",
          //   isadvertisingisaon_status: "1",
          //   point: 0,
          // };

          this.loading = false;
          // document.title = data.data ? data.data.ipa_name : "";
          if (data.data && data.data !== "undefined") {
            this.language = data.data.language;
            // this.$i18n.locale = this.language === "eng" ? 'en' : 'zh';
            switch (this.language) {
              case "eng":
                this.$i18n.locale = "en";
                break;
              case "tw":
                this.$i18n.locale = "tw";
                break;
              case "taiwan":
                this.$i18n.locale = "tw";
                break;
              case "chinese":
                this.$i18n.locale = "zh";
                break;
              case "vietnam":
                this.$i18n.locale = "vi";
                break;
              default:
                this.$i18n.locale = "zh";
                break;
            }
          }
          // PC端显示二维码逻辑
          if (
            navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
          ) {
            // 移动端
          } else {
            var ua = navigator.userAgent;
            if (
              ua ==
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.1 Mobile/15E148 Safari/604.1" ||
              ua ==
                "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1" ||
              ua ==
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0 (iPhone; CPU iPhone OS 13_5_1 like Mac OS X; zh-CN) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/17F80 UCBrowser/13.5.8.1568 Mobile  AliApp(TUnionSDK/0.1.20.4)" ||
              ua ==
                "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1" ||
              ua ==
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1.2 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0(Macintosh:IntelMacOSX1015 6)AppleWebKit/605.1.15(KHTML like Gecko) Version/14.1.1 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15" ||
              ua ==
                "Mozilla/5.0 (Linux; Android 10; TAS-AL00; HMSCore 6.4.0.311) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.105 HuaweiBrowser/12.1.0.301 Mobile Safari/537.36" ||
              ua ==
                "Mozilla/5.0 (Linux; Android 10; PCT-AL10 Build/HUAWEIPCT-AL10; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/83.0.4103.106 Mobile Safari/537.36" ||
              ua ==
                "Mozilla/5.0 (Linux; Android 11; V2046A Build/RP1A.200720.012; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.89 Mobile Safari/537.36 T7/13.12 SP-engine/2.52.0 baiduboxapp/13.14.0.11 (Baidu; P1 11) NABar/1.0" ||
              ua ==
                "Mozilla/5.0 (Windows; U; Windows NT 5.2; en-US) AppleWebKit/537.36 (KHTML, like Gecko) Safari/537.36 VivoBrowser/11.8.5.6 Chrome/87.0.4280.141" ||
              ua ==
                "Mozilla/5.0 (Linux; Android 11; VNE-AN00 Build/HONORVNE-AN00; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/86.0.4240.99 XWEB/4317 MMWEBSDK/20220903 Mobile Safari/537.36 MMWEBID/931 MicroMessenger/8.0.28.2240(0x28001C57) WeChat/arm64 Weixin NetType/WIFI Language/zh_CN ABI/arm64"
            ) {
              // 移动端
            } else {
              // PC端
              this.getQrCode(data.data.id);
              // return;
            }
          }

          if (data.code === 1) {
            this.appInfo = data.data;
            this.$nextTick(() => {
              // var mySwiper = new Swiper(".swiper-container", {
              //   autoplay: true, //可选选项，自动滑动
              //   loop: true,
              // });
            });
            this.queryQiniuToken(this.appInfo);
          } else if (data.code === 2) {
            var pla = ismobile(1);
            if (pla == 0 && data.data) {
              // 苹果手机
              window.location.href = data.data;
            }
          } else {
            if (data.code === 3) {
              this.msg =
                this.language === "eng"
                  ? "This app developer does not have a real-name, please conduct a real-name authentication as soon as possible"
                  : "此应用开发者未实名，请尽快进行实名认证";
              return;
            } else if (data.code === 4) {
              this.msg =
                this.language === "eng"
                  ? "This app does not comply with the platform rules and is temporarily removed."
                  : "此应用不符合平台规则，暂时下架。";
              return;
            } else if (data.code === 5) {
              this.msg =
                this.language === "eng"
                  ? "This application has not been downloaded enough times, please recharge, refresh and try again"
                  : "此应用下载次数不足，请充值后刷新重试";
              return;
            }
            this.msg = data.msg;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.modalClass {
  background: #fff;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
  width: 200px;
  // height: 300px;
  border-radius: 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.confirm {
  // width: 68px;
  padding: 0 25px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #1890ff;
  font-size: 14px;
  border-radius: 16px;
}
@import url(../assets/theme.less);
</style>
